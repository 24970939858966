import { s as serialize } from './shared/ohash.D__AXeF1.mjs';
export { i as isEqual } from './shared/ohash.D__AXeF1.mjs';
import { digest } from 'ohash/crypto';
export { digest } from 'ohash/crypto';

function hash(input) {
  return digest(serialize(input));
}

export { hash, serialize };
